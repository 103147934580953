import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer className="bg-purple-700" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to={"/#retail"} className="text-base text-gray-300 hover:text-white">
                      Retail
                    </Link>
                  </li>

                  <li>
                    <Link to={"/#curbside"} className="text-base text-gray-300 hover:text-white">
                      Curbside
                    </Link>
                  </li>

                  <li>
                    <Link to={"/#moto"} className="text-base text-gray-300 hover:text-white">
                      Billing and MOTO
                    </Link>
                  </li>

                  <li>
                    <Link to={"/#field"} className="text-base text-gray-300 hover:text-white">
                      Field Services
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a href={"mailto:support@smartpay21.com"} className="text-base text-gray-300 hover:text-white" >
                      Contact
                    </a>
                  </li>

                  <li>
                    <a href={"https://smartpay21.statuspage.io"} target={"_blank"} rel={"noreferrer"} className="text-base text-gray-300 hover:text-white">
                      API Status
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about" className="text-base text-gray-300 hover:text-white">
                      About
                    </Link>
                  </li>

                  <li>
                    <Link to="/contact" className="text-base text-gray-300 hover:text-white">
                      Contact
                    </Link>
                  </li>

                  <li>
                    <Link to="/about" className="text-base text-gray-300 hover:text-white">
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0"/>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <p className="mt-8 text-xs text-gray-400 md:mt-0 md:order-1">
            &copy; 2021 SmartPay 21. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
