import React, { useEffect } from "react"
import PropTypes from "prop-types"
import firebase from "gatsby-plugin-firebase"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  useEffect(() => {
    firebase.analytics();
  })

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
