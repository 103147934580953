import {useEffect} from 'react'

function ClickAway(ref, toggle) {
  useEffect(() => {
    function handleClickAway(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggle()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [ref, toggle]);
}

export default ClickAway
