import React from "react"
import { useState, useRef } from "react"
import { Transition } from "@headlessui/react"
import { Link } from "gatsby"
import ClickAway from "./utils/clickAwayHandler"

const SolutionsMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const clickAwayRef = useRef(null)
  ClickAway(clickAwayRef, () => setIsOpen(false))

  return (
    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} type="button"
              ref={clickAwayRef}
              className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <span>Solutions</span>
        <svg className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 transition ease-in-out duration-150"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd" />
        </svg>
      </button>

      <Transition
        show={isOpen}
        enter="ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div ref={ref}
               className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-6 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                <Link to="/#retail"
                   className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div
                    className="flex-shrink-0 flex justify-center h-10 w-10 sm:w-12">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 615 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M547.69,286.8a102.1,102.1,0,0,0,16.4-3.6V480a32,32,0,0,1-32,32H84a32,32,0,0,1-32-32V283.2a125.89,125.89,0,0,0,16.4,3.6,135.49,135.49,0,0,0,18,1.2,132.81,132.81,0,0,0,29.51-3.8V384H500V284.2a127.12,127.12,0,0,0,29.51,3.8A138.38,138.38,0,0,0,547.69,286.8Z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M602,118.6c33.6,53.6,3.8,128-59,136.4a102.9,102.9,0,0,1-13.7.9,99.08,99.08,0,0,1-73.81-33.1A98.83,98.83,0,0,1,316,230.88a96.26,96.26,0,0,1-8.08-8.08,98.9,98.9,0,0,1-139.62,8,97.4,97.4,0,0,1-8-8,98.75,98.75,0,0,1-73.81,33.1,104.6,104.6,0,0,1-13.7-.9C10.12,246.5-19.58,172.1,14,118.6L78.83,15A32,32,0,0,1,106,0H510a32,32,0,0,1,27.07,15Z" />
                      </g>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Retail Solutions
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      SmartPay 21 is the fast and secure way to pay in retail.
                    </p>
                  </div>
                </Link>

                <Link to="/#curbside"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div
                    className="flex-shrink-0 flex justify-center h-10 w-10 sm:w-12">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M319.5 128a48 48 0 0 1 44.57 30.17L384 208H128l19.93-49.83A48 48 0 0 1 192.5 128zM80 384a63.82 63.82 0 0 1-47.57-21.2A31.82 31.82 0 0 0 32 368v48a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-32zm352 0h-48v32a32 32 0 0 0 32 32h32a32 32 0 0 0 32-32v-48a31.82 31.82 0 0 0-.43-5.2A63.82 63.82 0 0 1 432 384z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M500 176h-59.88l-16.64-41.6A111.43 111.43 0 0 0 319.5 64h-127a111.47 111.47 0 0 0-104 70.4L71.87 176H12A12 12 0 0 0 .37 190.91l6 24A12 12 0 0 0 18 224h20.08A63.55 63.55 0 0 0 16 272v48a64 64 0 0 0 64 64h352a64 64 0 0 0 64-64v-48a63.58 63.58 0 0 0-22.07-48H494a12 12 0 0 0 11.64-9.09l6-24A12 12 0 0 0 500 176zm-352.07-17.83A48 48 0 0 1 192.5 128h127a48 48 0 0 1 44.57 30.17L384 208H128zM96 256c19.2 0 48 28.71 48 47.85s-28.8 15.95-48 15.95-32-12.8-32-31.9S76.8 256 96 256zm272 47.85c0-19.14 28.8-47.85 48-47.85s32 12.76 32 31.9-12.8 31.9-32 31.9-48 3.2-48-15.95z" />
                      </g>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Curbside Payments
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Take curbside payments as easily as in-store retail.
                    </p>
                  </div>
                </Link>

                <Link to="/#moto"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div
                    className="flex-shrink-0 flex justify-center h-10 w-10 sm:w-12">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M64,257.62,227.91,376a47.9,47.9,0,0,0,56.21,0L448,257.62V96a32,32,0,0,0-32-32H96A32,32,0,0,0,64,96Zm176-122.5V119a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8v16.29a55.31,55.31,0,0,1,32.27,12.18c2.1,2.23,3.74,7.49-.34,11.31L292.18,170a8.19,8.19,0,0,1-10.13.73A24.15,24.15,0,0,0,269.23,167H241.12c-6.5,0-11.8,5.92-11.8,13.19,0,5.95,3.61,11.18,8.77,12.73l45,13.5c18.58,5.58,31.58,23.39,31.58,43.39,0,24.53-19.05,44.44-42.67,45.07V311a8,8,0,0,1-8,8H248a8,8,0,0,1-8-8V294.71c-19.83-1-32.35-12.18-32.22-12.18a8,8,0,0,1,.34-11.31L219.87,260a8.22,8.22,0,0,1,10.13-.73A24.07,24.07,0,0,0,242.77,263h28.11c6.5,0,11.8-5.92,11.8-13.19,0-5.95-3.61-11.18-8.77-12.73l-45-13.5c-18.58-5.58-31.58-23.39-31.58-43.39C197.33,155.67,216.38,135.75,240,135.12Z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M329.4,41.44C312.6,29.18,279.2-.35,256,0c-23.2-.35-56.59,29.17-73.41,41.44L152,64H360ZM493.61,163c-2.94-2.3-21.73-16.26-45.61-33.93V257.61l64-46.24V200.72A48,48,0,0,0,493.61,163ZM64,129c-23.88,17.69-42.67,31.65-45.61,34A48,48,0,0,0,0,200.72v10.65l64,46.24ZM256,417.13a79.83,79.83,0,0,1-46.86-15.19L0,250.86V464a48,48,0,0,0,48,48H464a48,48,0,0,0,48-48V250.86L302.86,401.94A80,80,0,0,1,256,417.13Zm-16-282V119a8,8,0,0,1,8-8h16a8,8,0,0,1,8,8v16.29a55.31,55.31,0,0,1,32.27,12.18c2.1,2.23,3.74,7.49-.34,11.31L292.18,170a8.19,8.19,0,0,1-10.13.73A24.15,24.15,0,0,0,269.23,167H241.12c-6.5,0-11.8,5.92-11.8,13.19,0,5.95,3.61,11.18,8.77,12.73l45,13.5c18.58,5.58,31.58,23.39,31.58,43.39,0,24.53-19.05,44.44-42.67,45.07V311a8,8,0,0,1-8,8H248a8,8,0,0,1-8-8V294.71c-19.83-1-32.35-12.18-32.22-12.18a8,8,0,0,1,.34-11.31L219.87,260a8.22,8.22,0,0,1,10.13-.73A24.07,24.07,0,0,0,242.77,263h28.11c6.5,0,11.8-5.92,11.8-13.19,0-5.95-3.61-11.18-8.77-12.73l-45-13.5c-18.58-5.58-31.58-23.39-31.58-43.39C197.33,155.67,216.38,135.75,240,135.12Z" />
                      </g>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Billing and MOTO
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      SmartPay 21 works perfectly with mail, phone, and billing services.
                    </p>
                  </div>
                </Link>

                <Link to="/#field"
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">
                  <div
                    className="flex-shrink-0 flex justify-center h-10 w-10 sm:w-12">
                    <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                         aria-hidden="true">
                      <g className="fa-group">
                        <path className="text-purple-300" fill="currentColor"
                              d="M554.06 161.16L416 224v288l139.88-55.95A32 32 0 0 0 576 426.34V176a16 16 0 0 0-21.94-14.84zM20.12 216A32 32 0 0 0 0 245.66V496a16 16 0 0 0 21.94 14.86L160 448V214.92a302.84 302.84 0 0 1-21.25-46.42zM288 359.67a47.78 47.78 0 0 1-36.51-17C231.83 319.51 210.92 293.09 192 266v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72A47.8 47.8 0 0 1 288 359.67z" />
                        <path className="text-purple-700" fill="currentColor"
                              d="M288 0a126 126 0 0 0-126 126c0 56.26 82.35 158.8 113.9 196a15.77 15.77 0 0 0 24.2 0C331.65 284.8 414 182.26 414 126A126 126 0 0 0 288 0zm0 168a42 42 0 1 1 42-42 42 42 0 0 1-42 42z" />
                      </g>
                    </svg>
                  </div>
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      Field Services
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Take SmartPay 21 anywhere!
                    </p>
                  </div>
                </Link>
              </div>

              <div className="p-5 bg-gray-50 sm:p-8">
                <Link to="#"
                      className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150">
                  <span className="flex items-center">
                    <span className="text-base font-medium text-gray-900">
                      Questions?
                    </span>
                  </span>
                  <span className="mt-1 block text-sm text-gray-500">
                    Not finding a solution that fits your needs? Contact us!
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default SolutionsMenu
