import React from "react"
import { Link } from "gatsby"

import PrimaryButton from "./primary-button"
import SolutionsMenu from "./solutions-menu"
import MobileMenu from "./mobile-menu"
import Logo from "../images/smartpay_21.svg"

const Header = () => {
  const page = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <header>
      <div className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div
            className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="/">
                <span className="sr-only">SmartPay 21</span>
                <img className="h-5 w-auto sm:h-6" src={Logo}
                     alt="SmartPay 21" />
              </Link>
            </div>
            <MobileMenu />
            <nav className="hidden md:flex">
              <Link to="/" className={`text-base font-medium mr-10 ${(page === "/") ? "text-purple-800 cursor-default " : "text-gray-500 hover:text-purple-500"}`}>
                Home
              </Link>
              <SolutionsMenu />
              <Link to="/about" className={`text-base font-medium ml-10 ${(page === "/about") ? "text-purple-800 cursor-default " : "text-gray-500 hover:text-purple-500"}`}>
                About
              </Link>
              <Link to="/contact" className={`text-base font-medium ml-10 ${(page === "/contact") ? "text-purple-800 cursor-default " : "text-gray-500 hover:text-purple-500"}`}>
                Contact
              </Link>
            </nav>
            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
              <PrimaryButton LinkTo={"/contact"} ButtonText={"Get Started"} />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
